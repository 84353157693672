<template>
  <div>
    <div class="bgStyle">
      <div class="title">项目模板 {{ title }}</div>
    </div>
    <div class="bgStyles">
      <a-row>
        <a-col :span="6" order="1">
          <a-card v-model="model" :bordered="false">
            <a-row :gutter="16">
              <a-col :span="12">
                <p class="p">
                  <label>发布人</label>：{{ model.publisherName }}
                </p>
              </a-col>
              <a-col :span="12">
                <p class="p">
                  <label>联系方式</label>：{{ model.phoneNumber }}
                </p>
              </a-col>
            </a-row>
            <a-row>
              <p class="p">
                <label>模板类型</label>：{{
                  model.type == 1 ? "公开" : "不公开"
                }}
              </p>
            </a-row>
            <a-row>
              <p class="p">
                <label>发布时间</label>：{{
                  moment(model.creationTime).format(dateFormat)
                }}
              </p>
            </a-row>
            <a-row>
              <p class="p"><label>模板描述</label>：{{ model.description }}</p>
            </a-row>
            <a-row style="margin-right: 20px">
              <a-button
                type="primary"
                @click="editclick(model)"
                class="abutton"
                :disabled="!model.isMyTemplate"
              >
                编辑模板
              </a-button>
              <a-button type="primary" @click="click(model)" class="abutton">
                创建项目
              </a-button>
            </a-row>
            <div style="white-space: nowrap">
              <a-row :gutter="16">
                <a-col :span="10">
                  <a-card>
                    <p class="p" style="text-align: center">引用</p>
                    <p>
                      (已被引用<a>{{ model.quoteNumber }}</a
                      >次)
                    </p>
                  </a-card>
                </a-col>
                <a-col :span="10">
                  <a-card>
                    <p class="p" style="text-align: center">收藏</p>
                    <p>
                      (已被收藏<a>{{ model.collectionNumber }}</a
                      >次)
                    </p>
                  </a-card>
                </a-col>
                <a-col :span="8"> </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>
        <a-col :span="18" order="2">
          <div class="right" ref="personWrap">
            <div class="mt">
              <div class="mk">
                <div
                  v-for="carddata in data"
                  :key="carddata.planName"
                  class="mj"
                >
                  <a-card
                    :title="carddata.planName"
                    :bodyStyle="{ padding: '10px' }"
                  >
                    <div
                      v-infinite-scroll="
                        handleInfiniteOnLoad(carddata.projectTask)
                      "
                      :infinite-scroll-disabled="busy"
                      class="demo-infinite-container"
                      :infinite-scroll-distance="3"
                    >
                      <template v-for="item in carddata.projectTask">
                        <a-card
                          class="list-item"
                          :key="item.name"
                          @click="taskCardClick(item, carddata.planName)"
                        >
                          <p>{{ item.name }}</p>
                        </a-card>
                      </template>
                    </div>
                  </a-card>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <edit-template-details
      ref="edit"
      :visible="editCtrl.visible"
      :form="editCtrl.form"
      @updateEntiy="updateEntiy($event)"
      @submited="
        () => {
          this.editCtrl.visible = false;
        }
      "
      @cancel="
        () => {
          this.editCtrl.visible = false;
        }
      "
    ></edit-template-details>
    <get-task-details
      ref="get"
      :visible="getCtrl.visible"
      :planName="getCtrl.planName"
      :form="getCtrl.form"
      @cancel="
        () => {
          this.getCtrl.visible = false;
        }
      "
    >
    </get-task-details>
  </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import EditTemplateDetails from "./EditTemplateDetails.vue";
import moment from "moment";
import GetTaskDetails from "./GetTaskDetails.vue";

export default {
  components: { EditTemplateDetails, GetTaskDetails },
  directives: { infiniteScroll },
  computed: {
    title: {
      get: function () {
        return this.model.title;
      },
    },
  },
  data() {
    return {
      data: [], //项目计划数据源
      dateFormat: "YYYY-MM-DD",
      busy: false,
      loading: false,
      model: {}, //当前页面绑定实体
      editCtrl: {
        form: {},
        visible: false,
      },
      getCtrl: {
        form: {},
        visible: false,
        planName: "",
      },
    };
  },
  /** 组件生命周期创建前事件 */
  mounted() {
    this.model = this.$route.params;
    this.data = JSON.parse(this.model.extraProperties.plan, this.toCamelCase);
  },
  methods: {
    /**
     * 将json key转为小驼峰
     * 注：兼容旧数据
     * @param {string}  key 索引
     * @param {string}  value 返回值
     */
    toCamelCase(key, value) {
      if (value && typeof value === "object") {
        for (var k in value) {
          if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
            value[k.substring(0, 1).toLowerCase() + k.substring(1)] = value[k];
            delete value[k];
          }
        }
      }
      return value;
    },
    moment,
    /**
     * 创建项目按钮事件
     * @param {Guid} templateId 项目模板ID
     */
    click(templateId) {
      this.$router.push({
        name: "projectManage/add",
        params: {
          title: this.title,
          param: templateId,
        },
      });
    },
    /**
     * 编辑模板按钮事件
     * @param {Guid} templateId
     */
    editclick(model) {
      var entity=JSON.parse(JSON.stringify(model));
      this.editCtrl.form = entity;
      this.editCtrl.visible = true;
    },

    /**
     * 编辑模板按钮事件
     * @param {Guid} templateId
     */
    taskCardClick(item, planName) {
      this.getCtrl.form = item;
      this.getCtrl.visible = true;
      this.getCtrl.planName = planName;
    },

    /**
     * 任务列表下拉滚动
     * @param {obj} data List滚动条滚动事件
     */
    handleInfiniteOnLoad(data) {
      const data1 = data;
      this.loading = true;
      if (data1.length > 10) {
        this.busy = true;
        this.loading = false;
        return;
      }
    },
    /**
     * 编辑保存数据回写
     * @param {obj} entity 实体
     */
    updateEntiy(entity) {
      this.model = entity;
    },
  },
};
</script>

<style lang="scss" scoped>
.bgStyles {
  margin: 5px 10px;
  padding: 10px 15px;
  ::v-deep.__bar-is-vertical {
    right: -1px !important;
  }
}
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 8px;
  height: calc(100vh - 310px);
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.mt {
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: hidden;
}
.mk {
  white-space: nowrap;
}
.mj {
  margin: 0 5px;
  width: 330px;
  display: inline-block;
}
.p {
  margin: 5px;
  word-break: break-all;
  label {
    font-weight: bold;
  }
}
.abutton {
  margin: 10px;
  width: 100%;
}
.list-item {
  margin: 10px 0;
  cursor: pointer;
  white-space: normal;
}
.right {
  margin-left: 20px;
}
</style>
