<template>
  <a-modal
    title="基础信息"
    :visible="visible"
    @cancel="$emit('cancel')"
    width="500px"
  >
    <a-form-model
      class="formInfo"
      ref="registerForm"
      layout="horizontal"
      :model="registerForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="计划名称：">
        <p>{{ planName }}</p>
      </a-form-model-item>
      <a-form-model-item label="任务名称：" prop="type">
        <p>{{ registerForm.name }}</p>
      </a-form-model-item>
      <a-form-model-item label="描述：" prop="description" >
        <div v-html="registerForm.description"></div>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="click"> 取消 </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
//   computed: {
//     planName:{
//         get
//     }
//   },
  data() {
    return {
      registerForm: {
        title: "",
      },
    };
  },
  props: {
    visible: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
    planName: { type: String, default: "" },
  },
  methods: {
    click() {
      this.$emit("cancel");
    },
  },
  watch: {
    form: {
      handler() {
        this.registerForm = this.form;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
