<template>
  <a-modal
    title="编辑模板"
    :visible="visible"
    @ok="fomConfirm"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    width="700px"
    :okText="'保存'"
  >
    <a-form-model
      class="formInfo"
      ref="registerForm"
      layout="horizontal"
      :rules="rules"
      :model="registerForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="标题" prop="title">
        <a-input
          type="text"
          placeholder="标题"
          v-model="registerForm.title"
          autocomplete="off"
        />
      </a-form-model-item>
      <a-form-model-item label="是否公开" prop="type">
        <a-switch
          checked-children="是"
          un-checked-children="否"
          @click="switchChange"
          :default-checked="this.registerForm.type == 1 ? true : false"
        />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input
          type="textarea"
          placeholder="描述"
          v-model="registerForm.description"
          autocomplete="off"
          :rows="4"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateProjectTemplate } from "/src/api/projecttemplate.js";

export default {
  computed: {
    /** 是否公开下拉计算绑定属性 */
    defaultType: {
      get: function () {
        return this.registerForm.type;
      },
      set: function (value) {
        this.registerForm.type = value;
      },
    },
  },
  data() {
    let descriptionValidator = (rule, value, callback) => {
      if (value != null && value.length > 500)
        callback("描述不能超个500个字符");
      callback();
    };
    return {
      registerForm: {
        title: "",
      },
      /** 表单验证规则 */
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        description: [{ validator: descriptionValidator, trigger: "blur" }],
      },
    };
  },
  props: {
    visible: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
  },
  methods: {
    /**
     * switch开关蛋击事件
     * @param {bool} checked 是否打开开关
     */
    switchChange(checked) {
      this.registerForm.type = checked ? 1 : 2;
    },
    /** 保存事件 */
    fomConfirm() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          updateProjectTemplate(this.registerForm.id, this.registerForm)
            .then(() => {
              this.$emit("updateEntiy", this.registerForm);
              this.$message.success("修改成功");
              this.$emit("cancel");
            })
            .catch(() => {
              this.$message.error("修改失败");
            });
        }
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.registerForm = this.form;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
